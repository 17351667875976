import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import HeroNew from './components/Hero';
import style from './ResellerPageNew.module.scss';
import SecurePrivacy from './components/SecurePrivacy';
import ChooseProgram from './components/ChooseProgram';
import ProgramBenefits from './components/ProgramBenefits';
import TrustedByCompanies from './components/TrustedByCompanies/TrustedByCompanies';

const ResellerPageNew = ({ content, metatitle, canonical }) => {
  console.log('content', content);

  const sections = content.map((item, index) => {
    switch (item.slice_type) {
      case 'hero_new':
        return <HeroNew key={index} {...item} />;

      default:
        return null;
    }
  });

  const cardSections = content.map((item, index) => {
    switch (item.primary?.title?.richText[0]?.text) {
      case 'Why Partner with Secure Privacy?':
        return <SecurePrivacy key={index} {...item} />;

      case 'Choose The Program':
        return <ChooseProgram key={index} {...item} />;

      case 'Secure Privacy Program Benefits':
        return <ProgramBenefits key={index} {...item} />;

      case `Trusted by the world's most innovative companies`:
        return <TrustedByCompanies key={index} {...item} />;

      default:
        return null;
    }
  });

  return (
    <div className={style.container}>
      {sections}

      {cardSections}

      {/* <TrustedByCompanies /> */}

      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle.text}
        pageUrl={canonical.text}
      />
    </div>
  );
};

ResellerPageNew.propTypes = {
  content: PropTypes.array.isRequired,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
};

export default ResellerPageNew;
